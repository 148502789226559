import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of, throwError } from 'rxjs';
import { tap } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';
import * as alertFunctions from 'app/shared/models/sweet-alerts';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
    constructor(private router: Router) {}

    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {

        return next.handle(request).pipe(
            catchError((err) => {
                if (
                    err instanceof HttpErrorResponse &&
                    err.error instanceof Blob &&
                    err.error.type === 'application/json'
                ) {
                    return new Promise<any>((resolve, reject) => {
                        let reader = new FileReader();
                        reader.onload = (e: Event) => {
                            try {
                                const errmsg = JSON.parse(
                                    (<any>e.target).result
                                );
                                reject(
                                    new HttpErrorResponse({
                                        error: errmsg,
                                        headers: err.headers,
                                        status: err.status,
                                        statusText: err.statusText,
                                        url: err.url,
                                    })
                                );
                            } catch (e) {
                                reject(err);
                            }
                        };
                        reader.onerror = (e) => {
                            reject(err);
                        };
                        reader.readAsText(err.error);
                    });
                }
                return throwError(err);
            }),
            tap(
                (incoming: any) => {},
                (error: HttpErrorResponse) => {
                    let errMsg = '';
                    // Client Side Error
                    if (error.error instanceof ErrorEvent) {
                        errMsg = `${error.message} `;
                    } else {
                        // Server Side Error
                        // errMsg = `Error Code: ${error.status}, Message: ${error.message} `;
                        errMsg = `Message: ${error.message} `;
                        switch (error.status) {
                            case 400: // Bad Request = ApiException
                            if (error.error.errors) {
                                errMsg = '';
                                for (let key in error.error.errors) {
                                    let err = error.error.errors[key];
                                    err.forEach(text => {
                                        errMsg += text + '<br>';
                                    });
                                }
                                alertFunctions.errorAlert(errMsg);
                            } else if (error.error) {
                                errMsg = '';
                                let errorObj = typeof error.error === 'string' ? JSON.parse(error.error) : null;

                                if (errorObj && errorObj.Errors && errorObj.Errors.length > 0) {
                                    errMsg = errorObj.Errors[0].Message;
                                } else {
                                    const err = (
                                        error.error.detail ||
                                        error.error.error ||
                                        error.error.title ||
                                        error.error.message ||
                                        error.message ||
                                        (error.error.error && error.error.error[0])
                                    );
                                    errMsg = err.replace('\r\n', '<br>');
                                }
                                alertFunctions.errorAlert(errMsg);
                            }
                            break;
                            case 401: // Unauthorized
                                localStorage.clear();
                                this.router.navigate(['']);
                                break;
                            case 500: // Internal Server Error
                                if (error.error) {
                                    const err =
                                        error.error.detail ||
                                        error.error.error ||
                                        error.error.title ||
                                        error.error.message ||
                                        error.message ||
                                        error.error.error[0];
                                    errMsg = err;
                                    alertFunctions.errorAlert(errMsg.replace('\n', '<br>'));
                                    break;
                                }
                        }
                    }
                    return of(errMsg);
                }
            )
        );
    }
}
