import { Route } from '@angular/router';
// import { AuthGuard } from 'app/core/auth/guards/auth.guard';
// import { NoAuthGuard } from 'app/core/auth/guards/noAuth.guard';
import { LayoutComponent } from 'app/layout/layout.component';
import { InitialDataResolver } from 'app/app.resolvers';
import { MsalGuard } from '@azure/msal-angular';

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [
    // Redirect empty path to '/example'
    {
        path: '',
        pathMatch: 'full',
        redirectTo: 'dashboards',
    },
    // Auth routes for guests
    {
        path: '',
        component: LayoutComponent,
        data: {
            layout: 'empty',
        },
        children: [
            {
                path: 'sign-up/:key',
                loadChildren: () =>
                    import('app/modules/auth/sign-up/sign-up.module').then(
                        (m) => m.AuthSignUpModule
                    ),
            },
        ],
    },

    // Landing routes
    {
        path: '',
        component: LayoutComponent,
        data: {
            layout: 'empty',
        },
        children: [
            {
                path: 'home',
                loadChildren: () =>
                    import('app/modules/landing/home/home.module').then(
                        (m) => m.LandingHomeModule
                    ),
            },
        ],
    },
    {
        path: '',
        canActivate: [MsalGuard],
        component: LayoutComponent,
        resolve: {
            initialData: InitialDataResolver,
        },
        children: [
            {
                path: 'dashboards',
                children: [
                    {
                        path: '',
                        loadChildren: () =>
                            import(
                                'app/modules/dashboards/dashboard.module'
                            ).then((m) => m.DashboardModule),
                    },
                ],
            },
            {
                path: 'projects',
                children: [
                    {
                        path: '',
                        loadChildren: () =>
                            import('app/modules/projects/projects.module').then(
                                (m) => m.ProjectsModule
                            ),
                    },
                ],
            },
            {
                path: 'libraries',
                children: [
                    {
                        path: '',
                        loadChildren: () =>
                            import(
                                'app/modules/libraries/libraries.module'
                            ).then((m) => m.LibrariesModule),
                    },
                ],
            },
            {
                path: 'pending-validations',
                children: [
                    {
                        path: '',
                        loadChildren: () =>
                            import(
                                'app/modules/pending-validations/pending-validations.module'
                            ).then((m) => m.PendingValidationsModule),
                    },
                ],
            },
        ],
    },
];
