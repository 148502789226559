<!-- Notifications toggle -->
<button mat-icon-button (click)="openPanel()" #notificationsOrigin>
    <ng-container *ngIf="unreadCountTotal > 0">
        <span class="absolute top-0 right-0 left-0 flex items-center justify-center h-3">
            <span
                class="flex items-center justify-center shrink-0 min-w-4 h-4 px-1 ml-4 mt-2.5 rounded-full bg-teal-600 text-indigo-50 text-xs font-medium">
                {{unreadCountTotal}}
            </span>
        </span>
    </ng-container>
    <mat-icon [svgIcon]="'heroicons_outline:bell'"></mat-icon>
</button>

<!-- Notifications panel -->
<ng-template #notificationsPanel>

    <div
        class="fixed inset-0 sm:static sm:inset-auto flex flex-col sm:min-w-90 sm:w-90 sm:rounded-2xl overflow-hidden shadow-lg">

        <!-- Header -->
        <div class="flex shrink-0 items-center py-4 pr-4 pl-6 bg-primary text-on-primary">
            <div class="sm:hidden -ml-1 mr-3">
                <button mat-icon-button (click)="closePanel()">
                    <mat-icon class="icon-size-5 text-current" [svgIcon]="'heroicons_solid:x'"></mat-icon>
                </button>
            </div>
            <div class="text-lg font-medium leading-10">{{'Unread messages' | translate}}</div>
            <div class="ml-auto">
                <mat-icon class="icon-size-5 text-current" [svgIcon]="'heroicons_solid:chat-alt-2'"></mat-icon>
            </div>
        </div>

        <!-- Content -->
        <div class="relative flex flex-col flex-auto sm:max-h-120 divide-y overflow-y-auto bg-card">
            <!-- Notifications -->
            <ng-container *ngIf="unreadCountTotal>0">
            <ng-container *ngFor="let notification of chatNotifications; trackBy: trackByFn">
                <div *ngIf="notification.total>0" class="flex group hover:bg-gray-50 dark:hover:bg-black dark:hover:bg-opacity-5 unread"
                    (click)="openProjectChat(notification.projectId)">

                    <!-- Notification with a link -->
                    <!-- Normal links -->
                    <a class="flex flex-auto py-5 pl-6 cursor-pointer">
                        <ng-container *ngTemplateOutlet="notificationContent"></ng-container>
                    </a>

                    <div class="relative flex flex-col my-5 mr-6 ml-2">
                        <!-- Indicator -->
                        <div
                            class="py-0.5 px-3 rounded-full text-sm font-semibold mr-2 text-blue-800 bg-blue-100 dark:text-blue-50 dark:bg-blue-500">
                            {{ notification.total }}
                        </div>
                    </div>
                </div>

                <!-- Notification content template -->
                <ng-template #notificationContent>
                    <!-- Icon -->
                    <div
                        class="flex shrink-0 items-center justify-center w-6 h-6 mr-4 rounded-full bg-gray-100 dark:bg-gray-700">
                        <div
                            class="flex items-center justify-center w-full h-full rounded-full text-lg uppercase bg-gray-200 text-gray-600 dark:bg-gray-700 dark:text-gray-200">
                            {{notification.projectName.charAt(0)}}
                        </div>
                    </div>
                    <!-- Title -->
                    <div class="flex flex-col flex-auto">
                        <div class="font-semibold line-clamp-1"> <span
                                class="text-lg flex items-center">{{notification.projectName}}</span></div>
                    </div>
                </ng-template>
            </ng-container>
            </ng-container>

            <!-- No notifications -->
            <ng-container *ngIf="!chatNotifications || !chatNotifications.length || unreadCountTotal===0">
                <div class="flex flex-col flex-auto items-center justify-center sm:justify-start py-12 px-8">
                    <div
                        class="flex flex-0 items-center justify-center w-14 h-14 rounded-full bg-primary-100 dark:bg-primary-600">
                        <mat-icon class="text-primary-700 dark:text-primary-50"
                            [svgIcon]="'heroicons_outline:bell'"></mat-icon>
                    </div>
                    <div class="mt-5 text-2xl font-semibold tracking-tight">{{'No unread messages' | translate}}</div>
                    <div class="w-full max-w-60 mt-1 text-md text-center text-secondary">{{'When you have messages,
                        they will appear here.' | translate}}</div>
                </div>
            </ng-container>
        </div>
    </div>
</ng-template>
