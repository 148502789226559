import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnDestroy,
    OnInit,
    ViewEncapsulation,
} from '@angular/core';
import { BooleanInput } from '@angular/cdk/coercion';
import { Subject } from 'rxjs';
import { UsersService } from 'app/shared/services/users.service';
import { User } from 'app/shared/models/user';
import { MsalService } from '@azure/msal-angular';
import { AssetsService } from 'app/shared/services/assets.service';
import { NgxPermissionsService } from 'ngx-permissions';
import { UserEditComponent } from './user-edit/user-edit.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
    selector: 'user',
    templateUrl: './user.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    exportAs: 'user',
})
export class UserComponent implements OnInit, OnDestroy {
    /* eslint-disable @typescript-eslint/naming-convention */
    static ngAcceptInputType_showAvatar: BooleanInput;
    /* eslint-enable @typescript-eslint/naming-convention */

    @Input() showAvatar: boolean = true;
    public user: User = new User();

    private _unsubscribeAll: Subject<any> = new Subject<any>();

    /**
     * Constructor
     */
    constructor(
        private usersService: UsersService,
        private msalService: MsalService,
        private assetsService: AssetsService,
        private permissionsService: NgxPermissionsService,
        private matDialog: MatDialog,
        private changeDetectorRef: ChangeDetectorRef
    ) {}

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this.getSelf();
    }

    getSelf() {
        this.usersService.getSelf().subscribe((res) => {
            this.user = res;

            this.changeDetectorRef.detectChanges();

            this.permissionsService.loadPermissions(
                this.user.isAdmin ? ['Admin'] : []
            );
        });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Sign out
     */
    signOut(): void {
        this.msalService.logoutRedirect();
    }

    editUser() {
        let userDialogRef = this.matDialog.open(UserEditComponent, {
            autoFocus: false,
            data: this.user,
        });

        userDialogRef.componentInstance.updateUserEvent.subscribe(() => {
            this.getSelf();
        });
    }
}
