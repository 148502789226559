<div class="fixed inset-0 flex items-center justify-center">
    <div class="custom-modal flex flex-col w-96 bg-white md:w-160 md:min-w-160 rounded-lg shadow-lg">
        <div class="flex flex-row modal-title rounded-lg">
            <div class="flex-1">
                <h2 class="text-xl font-semibold ">{{ 'Edit User' | translate }}</h2>
            </div>
            <div class="flex items-center justify-end cursor-pointer">
                <mat-icon (click)="userDialogRef.close()" [svgIcon]="'heroicons_solid:x'"></mat-icon>
            </div>
        </div>
        <div class="flex-grow overflow-y-auto">
            <div class="mt-6 flex justify-center">
                <span *ngIf="user.photoUrl && !user.image" class="relative">
                    <img class="w-16 h-16 rounded-full" [src]="user.photoUrl">
                </span>
                <span *ngIf="user.image" class="relative">
                    <img class="w-16 h-16 rounded-full" [src]="user.image">
                </span>
                <input id="image-file-input" class="absolute h-0 w-0 opacity-0 invisible pointer-events-none"
                    type="file" #imageFileInput [multiple]="false" [accept]="'image/jpeg, image/png'"
                    (change)="uploadFile($event.target.files)" (click)="resetFileInput()"/>
                <label *ngIf="!user.photoUrl && !user.image"
                    class="flex items-center justify-center w-10 h-10 rounded-full cursor-pointer"
                    for="image-file-input" matRipple>
                    <mat-icon class="icon-size-20" [svgIcon]="'heroicons_solid:user-add'"></mat-icon>
                </label>
                <mat-icon *ngIf="user.photoUrl || user.image" (click)="user.photoUrl = null; user.image = null; user.photo = null; user.photoId = null" class="icon-size-8"
                    style="position: relative;right: 18px;top: -10px; cursor: pointer"
                    [svgIcon]="'heroicons_solid:x-circle'" ></mat-icon>
            </div>
            <form class="m-4" [formGroup]="userEditForm" #userEditNgForm="ngForm">
                <mat-form-field class="w-full">
                    <mat-label>{{'Email address' | translate}}</mat-label>
                    <input id="email" matInput [formControlName]="'email'">
                </mat-form-field>
                <mat-form-field class="w-full mt-2">
                    <mat-label>{{'First name' | translate}}</mat-label>
                    <input id="firstName" matInput [formControlName]="'firstName'">
                    <mat-error *ngIf="userEditForm.get('firstName').hasError('required')">
                        {{'First name is required' | translate}}
                    </mat-error>
                </mat-form-field>
                <mat-form-field class="w-full mt-2">
                    <mat-label>{{'Last name' | translate}}</mat-label>
                    <input id="lastName" matInput [formControlName]="'lastName'">
                    <mat-error *ngIf="userEditForm.get('lastName').hasError('required')">
                        {{'Last name is required' | translate}}
                    </mat-error>
                </mat-form-field>
                <div class="flex items-center justify-end mt-2">
                    <button class="mr-1" mat-flat-button [color]="'accent'" (click)="userDialogRef.close()">
                        {{'Cancel' | translate}}
                    </button>
                    <button mat-flat-button [color]="'primary'" [disabled]="userEditForm.disabled || loadingButton"
                        (click)="updateUser()">
                        {{'Save' | translate}}
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>
