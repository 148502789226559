import { Component, OnInit } from '@angular/core';
import { MsalBroadcastService } from '@azure/msal-angular';
import { EventMessage, EventType } from '@azure/msal-browser';
import { MsalService } from '@azure/msal-angular';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
    constructor(
        private msalBroadcastService: MsalBroadcastService,
        private msalService: MsalService
    ) {}

    ngOnInit(): void {
        this.msalBroadcastService.msalSubject$.subscribe(
            (result: EventMessage) => {
                if (result.eventType === EventType.LOGIN_FAILURE) {
                    this.msalService.instance.loginRedirect();
                }
            }
        );
    }
}
