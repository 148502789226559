import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FuseNavigationService, FuseVerticalNavigationComponent } from '@fuse/components/navigation';
import { UsersService } from 'app/shared/services/users.service';
import { TranslateService } from '@ngx-translate/core';
import { Language } from 'app/shared/models/user';

@Component({
    selector: 'languages',
    templateUrl: './languages.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    exportAs: 'languages'
})
export class LanguagesComponent implements OnInit, OnDestroy {
    activeLang: string;
    public languageOptions: any;
    public selectedLanguage: any;
    public selectedFlag: string;
    public Language = Language;
    public availableLangs: {type: any, code: string, title: string, flag: string} [] = [];

    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _fuseNavigationService: FuseNavigationService,
        private usersService: UsersService,
        private translateService: TranslateService,
    ) {
    this.languageOptions = {
        en: {
            title: 'English',
            flag: 'US'
        },
        de: {
            title: 'Deutsch',
            flag: 'DE'
        }
    }

      for (const [propertyKey, propertyValue] of Object.entries(Language)) {
        if (!Number.isNaN(Number(propertyKey))) {
          continue;
        }

        this.availableLangs.push({
            type: propertyValue,
            code: propertyKey,
            ...this.languageOptions[propertyKey] || { title: null, flag: null },
          });
      }

    this.translateService.addLangs(this.availableLangs.map(language => language.code));
    }

    ngOnInit(): void {
        this.usersService.getSelf().subscribe((res) => {
            this._changeDetectorRef.detectChanges();
            this.translateService.setDefaultLang(Language[res.language]);
            this.selectedFlag = this.availableLangs.find((language)=>language.code===Language[res.language]).flag;
            this._changeDetectorRef.detectChanges();
        });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------
    /**
     * Track by function for ngFor loops
     *
     * @param index
     * @param item
     */
    trackByFn(index: number, item: any): any {
        return item.id || index;
    }

    /**
 * Set the language
 *
 * @param language
 */
    setLanguage(language): void {
        this.usersService
        .updateUserLanguage(language.type)
        .subscribe(() => {});
        this.selectedFlag = language.flag;

        // Use the selected language id for translations
        this.translateService.use(language.code);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Update the navigation
     *
     * @param lang
     * @private
     */
    private _updateNavigation(lang: string): void {
        // For the demonstration purposes, we will only update the Dashboard names
        // from the navigation but you can do a full swap and change the entire
        // navigation data.
        //
        // You can import the data from a file or request it from your backend,
        // it's up to you.

        // Get the component -> navigation data -> item
        const navComponent = this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>('mainNavigation');

        // Return if the navigation component does not exist
        if (!navComponent) {
            return null;
        }

        // Get the flat navigation data
        const navigation = navComponent.navigation;

        // Get the Project dashboard item and update its title
        const projectDashboardItem = this._fuseNavigationService.getItem('dashboards.project', navigation);
        if (projectDashboardItem) {
            // this._translocoService.selectTranslate('Project').pipe(take(1))
            //     .subscribe((translation) => {

            //         // Set the title
            //         projectDashboardItem.title = translation;

            //         // Refresh the navigation component
            //         navComponent.refresh();
            //     });
        }

        // Get the Analytics dashboard item and update its title
        const analyticsDashboardItem = this._fuseNavigationService.getItem('dashboards.analytics', navigation);
        if (analyticsDashboardItem) {
            // this._translocoService.selectTranslate('Analytics').pipe(take(1))
            //     .subscribe((translation) => {

            //         // Set the title
            //         analyticsDashboardItem.title = translation;

            //         // Refresh the navigation component
            //         navComponent.refresh();
            //     });
        }
    }
}
