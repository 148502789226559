import { FuseNavigationItem } from '@fuse/components/navigation';

export const defaultNavigation: FuseNavigationItem[] = [
    {
        id: 'dashboards',
        title: 'Dashboards',
        type: 'basic',
        icon: 'heroicons_outline:home',
        link: '/dashboards',
    },
    {
        id: 'projects',
        title: 'Projects',
        type: 'basic',
        icon: 'heroicons_outline:document-duplicate',
        link: '/projects',
    },
    {
        id: 'libraries',
        title: 'Libraries',
        type: 'basic',
        icon: 'heroicons_outline:library',
        link: '/libraries',
    },
    {
        id: 'pending-validations',
        title: 'Pending Validations',
        type: 'basic',
        icon: 'heroicons_outline:clipboard-check',
        link: '/pending-validations',
    },
];
export const compactNavigation: FuseNavigationItem[] = [
    {
        id: 'example',
        title: 'Example',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/example',
    },
];
export const futuristicNavigation: FuseNavigationItem[] = [
    {
        id: 'example',
        title: 'Example',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/example',
    },
];
export const horizontalNavigation: FuseNavigationItem[] = [
    {
        id: 'dashboards',
        title: 'Dashboards',
        type: 'basic',
        icon: 'heroicons_outline:home',
        link: '/dashboards',
    },
    {
        id: 'projects',
        title: 'Projects',
        type: 'basic',
        icon: 'heroicons_outline:document-duplicate',
        link: '/projects',
    },
    {
        id: 'libraries',
        title: 'Libraries',
        type: 'basic',
        icon: 'heroicons_outline:library',
        link: '/libraries',
    },
    {
        id: 'pending-validations',
        title: 'Pending Validations',
        type: 'basic',
        icon: 'heroicons_outline:clipboard-check',
        link: '/pending-validations',
    },
];
