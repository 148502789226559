<!-- Button -->
<button
    mat-icon-button
    [matMenuTriggerFor]="userActions"
    class="flex">
    <span class="relative">
        <img
            class="w-14 h-14 rounded-full border-solid border-2 border-[#5453f6]"
            *ngIf="user.photoUrl"
            [src]="user.photoUrl">
        <mat-icon
            class="icon-size-8"
            *ngIf="!user.photoUrl"
            [svgIcon]="'heroicons_outline:user-circle'"></mat-icon>
    </span>
</button>

<mat-menu
    [xPosition]="'before'"
    #userActions="matMenu">
    <button mat-menu-item>
        <span class="flex flex-col leading-none">
            <span>{{'Signed in as' | translate}}</span>
            <span class="mt-1.5 text-md font-medium">
                {{user?.firstName}}&nbsp;{{user?.lastName}}
            </span>
        </span>
    </button>
    <mat-divider class="my-2"></mat-divider>
    <button mat-menu-item (click)="editUser()">
        <mat-icon [svgIcon]="'heroicons_outline:user-circle'"></mat-icon>
        <span>{{'Profile' | translate}}</span>
    </button>
    <mat-divider class="my-2"></mat-divider>
    <button
        mat-menu-item
        (click)="signOut()">
        <mat-icon [svgIcon]="'heroicons_outline:logout'"></mat-icon>
        <span>{{'Sign out' | translate}}</span>
    </button>
</mat-menu>
