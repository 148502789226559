<!-- Button -->
<button
    mat-icon-button
    [matMenuTriggerFor]="languages">
    <img  *ngIf="selectedFlag" [src]="'assets/images/flags/' + selectedFlag + '.svg'" alt="Flag">
</button>

<!-- Language menu -->
<mat-menu
    [xPosition]="'before'"
    #languages="matMenu">
    <ng-container *ngFor="let lang of availableLangs; trackBy: trackByFn">
        <button
            mat-menu-item
            (click)="setLanguage(lang)">
            <span class="flex items-center">
                <img [src]="'assets/images/flags/' + lang.flag + '.svg'" alt="Flag">
                <span class="ml-3">{{lang.title}}</span>
            </span>
        </button>
    </ng-container>
</mat-menu>
