import {
    Component,
    EventEmitter,
    Inject,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { User } from 'app/shared/models/user';
import {
    NgForm,
    UntypedFormBuilder,
    UntypedFormGroup,
    Validators,
} from '@angular/forms';
import { UsersService } from 'app/shared/services/users.service';

@Component({
    selector: 'app-user-edit',
    templateUrl: './user-edit.component.html',
    styleUrls: ['./user-edit.component.scss'],
})
export class UserEditComponent implements OnInit {
    @ViewChild('userEditNgForm') userEditNgForm: NgForm;
    @ViewChild('imageFileInput') imageFileInput: any;
    @Output() updateUserEvent: EventEmitter<void> = new EventEmitter<void>();
    public userEditForm: UntypedFormGroup;
    public loadingButton = false;

    constructor(
        public userDialogRef: MatDialogRef<UserEditComponent>,
        private _formBuilder: UntypedFormBuilder,
        private usersService: UsersService,
        @Inject(MAT_DIALOG_DATA) public user: User
    ) {}

    ngOnInit(): void {
        this.userEditForm = this._formBuilder.group({
            firstName: [this.user.firstName, Validators.required],
            lastName: [this.user.lastName, Validators.required],
            email: [
                { value: this.user.email, disabled: true },
                [Validators.required, Validators.email],
            ],
        });
    }

    uploadFile(files: File) {
        this.user.photo = files[0];
        const reader = new FileReader();
        reader.onload = () => {
            this.user.image = reader.result.toString();
        };
        reader.readAsDataURL(files[0]);
    }

    updateUser() {;
        this.user.firstName = this.userEditForm.value.firstName;
        this.user.lastName = this.userEditForm.value.lastName;

        if (this.userEditForm.invalid) {
            return;
        }

        this.userEditForm.disable();
        this.loadingButton = true;

        this.usersService.updateUser(this.user).subscribe(
            () => {
                this.loadingButton = false;
                this.updateUserEvent.emit();
                this.userDialogRef.close();
            },
            (error) => {
                this.loadingButton = false;
                this.userEditForm.enable();
                this.userEditForm.get('email').setValue(this.user.email);
                this.userEditForm.get('email').disable();
            },
        );
    }

    resetFileInput() {
        this.imageFileInput.nativeElement.value = '';
      }
}
