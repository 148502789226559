import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ExtraOptions, PreloadAllModules, RouterModule } from '@angular/router';
import { FuseModule } from '@fuse';
import { FuseConfigModule } from '@fuse/services/config';
import { FuseMockApiModule } from '@fuse/lib/mock-api';
import { CoreModule } from 'app/core/core.module';
import { appConfig } from 'app/core/config/app.config';
import { mockApiServices } from 'app/mock-api';
import { LayoutModule } from 'app/layout/layout.module';
import { AppComponent } from 'app/app.component';
import { appRoutes } from 'app/app.routing';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import {
    HTTP_INTERCEPTORS,
    HttpClient,
    HttpClientModule,
} from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { MatSelectModule } from '@angular/material/select';
import { MsalInterceptor, MsalModule } from '@azure/msal-angular';
import { PublicClientApplication, InteractionType } from '@azure/msal-browser';
import { environment } from 'environments/environment';
import { HttpErrorInterceptor } from './shared/interceptors/error.interceptor';
import { NgxPermissionsModule } from 'ngx-permissions';
import {
    MAT_CHIPS_DEFAULT_OPTIONS,
    MatChipsDefaultOptions,
} from '@angular/material/chips';
import { COMMA, ENTER } from '@angular/cdk/keycodes';

const routerConfig: ExtraOptions = {
    preloadingStrategy: PreloadAllModules,
    scrollPositionRestoration: 'enabled',
};

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        RouterModule.forRoot(appRoutes, routerConfig),

        // Fuse, FuseConfig & FuseMockAPI
        FuseModule,
        FuseConfigModule.forRoot(appConfig),
        FuseMockApiModule.forRoot(mockApiServices),

        // Core module of your application
        CoreModule,

        // Layout module of your application
        LayoutModule,

        HttpClientModule,
        NgxPermissionsModule.forRoot(),

        // Translate module
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
        }),
        MatSelectModule,
        MsalModule.forRoot(
            new PublicClientApplication({
                auth: {
                    clientId: environment.clientId,
                    authority: environment.authority,
                    redirectUri: environment.redirectUri,
                    knownAuthorities: [environment.authorityDomain],
                    postLogoutRedirectUri: environment.redirectUri,
                },
                cache: {
                    cacheLocation: 'localStorage',
                    storeAuthStateInCookie: false,
                },
            }),
            {
                interactionType: InteractionType.Redirect,
                authRequest: {
                    scopes: ['openid'],
                },
            },
            {
                interactionType: InteractionType.Redirect,
                protectedResourceMap: new Map([
                    [environment.endpoint, environment.endpointScopes],
                ]),
            }
        ),
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: MsalInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpErrorInterceptor,
            multi: true,
        },
        {
            provide: MAT_CHIPS_DEFAULT_OPTIONS,
            useValue: {
                separatorKeyCodes: [ENTER, COMMA],
            },
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
